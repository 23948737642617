import React from "react";

function GenerateSessionExercises({
  slot,
  register,
  errors,
  handleInputChange,
  inputValues,
  allFieldsValid,
}) {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Ordenar los ejercicios por su propiedad order
  const sortedExercises = slot.exercises.sort((a, b) => a.order - b.order);

  return (
    <div className="mt-20">
      <h2 className="text-xl font-semibold leading-7 text-gray-900">
        {slot.slot_title} - {slot?.slot_description}
      </h2>
      <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        {sortedExercises.map((exercise) => (
          <div key={exercise.exercise_id} className="sm:col-span-4">
            <label
              htmlFor={`exercise${exercise.exercise_id}_session`}
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Ejercicio: {exercise.order}
            </label>
            <div className="mt-2">
              <div
                className={`flex rounded-md gap-x-3 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md ${
                  allFieldsValid[exercise.exercise_id]
                    ? "ring-green-500"
                    : "ring-red-300"
                }`}
              >
                <input
                  id={`exercise${exercise.exercise_id}_session`}
                  name={`exercise${exercise.exercise_id}_session`}
                  type="number"
                  {...register(`exercise${exercise.exercise_id}_session`, {
                    onChange: (e) => handleInputChange(e, exercise),
                  })}
                  value={inputValues[exercise.exercise_id] || ""}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-800 focus:ring-0 sm:text-sm sm:leading-6"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
              <div>
                {exercise.repetitions === "true" && (
                  <>
                    <label
                      htmlFor={`exercise${exercise.exercise_id}_session_repetitions`}
                      className="block text-sm font-medium leading-6 text-gray-900 mt-3"
                    >
                      Repeticiones
                    </label>
                    <div className="flex rounded-md gap-x-3 w-32 shadow-sm ring-1 ring-inset mt-2 ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        id={`exercise${exercise.exercise_id}_session_repetitions`}
                        name={`exercise${exercise.exercise_id}_session_repetitions`}
                        type="text"
                        {...register(
                          `exercise${exercise.exercise_id}_session_repetitions`,
                          {
                            required: "Debes ingresar las repeticiones",
                          }
                        )}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                    {errors[
                      `exercise${exercise.exercise_id}_session_repetitions`
                    ] && (
                      <p className="h-4 text-sm text-red-500">
                        {
                          errors[
                            `exercise${exercise.exercise_id}_session_repetitions`
                          ].message
                        }
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="flex gap-x-5 items-center mt-4">
                {exercise.filter_material && (
                  <p className="px-3 py-2 rounded-sm text-sm text-white bg-red-500">
                    {capitalizeFirstLetter(exercise.filter_material)}
                  </p>
                )}
                {exercise.filter_body_part && (
                  <p className="px-3 py-2 rounded-sm text-sm text-white bg-red-500">
                    {capitalizeFirstLetter(exercise.filter_body_part)}
                  </p>
                )}
                {exercise.warning && (
                  <p className="px-3 py-2 rounded-sm text-sm text-white bg-yellow-500">
                    {exercise.warning}
                  </p>
                )}
                {/* {exercise.repetitions && (
                  <p className="px-3 py-2 rounded-sm text-sm text-white bg-green-500">
                    {exercise.repetitions}
                  </p>
                )} */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GenerateSessionExercises;
