import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";

function UpdateTemplateSlotForm({ slots, render, setRender }) {
  const { templatesRepository } = useTemplatesRepository();

  const { register, formState } = useForm({
    mode: "onTouched",
  });

  const { errors } = formState;

  const handleDeleteSlot = async (slotId) => {
    try {
      await templatesRepository.deleteSlot(slotId);
      toast.success("Bloque eliminado correctamente");
      setRender(!render);
    } catch (error) {
      console.error(error);
      toast.error("Error al eliminar el bloque");
    }
  };

  const renderSlots = (slots) => {
    const sortedSlots = slots.sort((a, b) => a.order - b.order);

    return sortedSlots.map((slot, index) => (
      <>
        <form key={slot?.slot_id} className="border p-4 mb-4 mt-20">
          <h2 className="text-xl font-bold mb-4">Bloque {slot?.order}</h2>
          <h3 className="text-lg font-semibold">{slot?.slot_title}</h3>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor={`slot_title_${slot?.slot_id}`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Título
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id={`slot_title_${slot?.slot_id}`}
                    defaultValue={slot?.slot_title}
                    readOnly
                    {...register(`slot_title_${slot?.slot_id}`)}
                    className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor={`slot_visibility_${slot?.slot_id}`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                ¿Es invisible?
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id={`slot_visibility_${slot?.slot_id}`}
                    defaultValue={slot?.slot_visibility ? "Sí" : "No"}
                    readOnly
                    {...register(`slot_visibility_${slot?.slot_id}`)}
                    className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor={`slot_description_${slot?.slot_id}`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Descripción
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id={`slot_description_${slot?.slot_id}`}
                    defaultValue={slot?.slot_description}
                    readOnly
                    {...register(`slot_description_${slot?.slot_id}`)}
                    className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor={`slot_extra_info_sup_${slot?.slot_id}`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Extra Info Sup
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id={`slot_extra_info_sup_${slot?.slot_id}`}
                    defaultValue={slot?.slot_extra_info_sup}
                    readOnly
                    {...register(`slot_extra_info_sup_${slot?.slot_id}`)}
                    className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor={`slot_extra_info_sub_${slot?.slot_id}`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Extra Info Sub
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id={`slot_extra_info_sub_${slot?.slot_id}`}
                    defaultValue={slot?.slot_extra_info_sub}
                    readOnly
                    {...register(`slot_extra_info_sub_${slot?.slot_id}`)}
                    className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Ejercicios
              </label>
              {slot?.exercises
                .sort((a, b) => a.order - b.order)
                .map((field, index) => (
                  <div key={field.exercise_id} className="mt-20">
                    <div className="space-y-12">
                      <div className="border-b border-gray-900/10 pb-12 max-w-4xl mx-auto">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Ejercicio {index + 1}
                        </h2>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor={`exercise${field.exercise_id}_filter_material`}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Filtro de material
                            </label>
                            <div className="mt-2">
                              <select
                                id={`exercise${field.exercise_id}_filter_material`}
                                name={`exercise${field.exercise_id}_filter_material`}
                                readOnly
                                {...register(
                                  `exercise${field.exercise_id}_filter_material`
                                )}
                                value={field.filter_material}
                                onChange={(e) =>
                                  handleFieldChange(
                                    field.exercise_id,
                                    "filter_material",
                                    e.target.value
                                  )
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option>
                                  {field?.filter_material.toUpperCase()}
                                </option>
                              </select>
                            </div>
                            <p className="h-4 text-sm text-red-500">
                              {errors[
                                `exercise${field?.exercise_id}_filter_material`
                              ] &&
                                errors[
                                  `exercise${field?.exercise_id}_filter_material`
                                ].message}
                            </p>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor={`exercise${field?.exercise_id}_filter_body_part`}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Filtro de parte corporal
                            </label>
                            <div className="mt-2">
                              <select
                                id={`exercise${field?.exercise_id}_filter_body_part`}
                                name={`exercise${field?.exercise_id}_filter_body_part`}
                                readOnly
                                {...register(
                                  `exercise${field?.exercise_id}_filter_body_part`
                                )}
                                value={field?.filter_body_part}
                                onChange={(e) =>
                                  handleFieldChange(
                                    field?.exercise_id,
                                    "filter_body_part",
                                    e.target.value
                                  )
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              >
                                <option>
                                  {field?.filter_body_part.toUpperCase()}
                                </option>
                              </select>
                            </div>
                            <p className="h-4 text-sm text-red-500">
                              {errors[
                                `exercise${field?.exercise_id}_filter_body_part`
                              ] &&
                                errors[
                                  `exercise${field?.exercise_id}_filter_body_part`
                                ].message}
                            </p>
                          </div>
                          <div className="sm:col-span-4">
                            <label
                              htmlFor={`exercise${field?.exercise_id}_repetitions`}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Repeticiones
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                  id={`exercise${field?.exercise_id}_repetitions`}
                                  name={`exercise${field?.exercise_id}_repetitions`}
                                  type="text"
                                  readOnly
                                  defaultValue={
                                    field?.repetitions === "true" ? "Sí" : "No"
                                  }
                                  {...register(
                                    `exercise${field?.exercise_id}_repetitions`
                                  )}
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <p className="h-4 text-sm text-red-500">
                                {errors[
                                  `exercise${field?.exercise_id}_repetitions`
                                ] &&
                                  errors[
                                    `exercise${field?.exercise_id}_repetitions`
                                  ].message}
                              </p>
                            </div>
                          </div>
                          <div className="sm:col-span-4">
                            <label
                              htmlFor={`exercise${field?.exercise_id}_warning`}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Advertencia
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                  id={`exercise${field?.exercise_id}_warning`}
                                  name={`exercise${field?.exercise_id}_warning`}
                                  type="text"
                                  readOnly
                                  defaultValue={field?.warning}
                                  {...register(
                                    `exercise${field.exercise_id}_warning`
                                  )}
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <p className="h-4 text-sm text-red-500">
                                {errors[
                                  `exercise${field?.exercise_id}_warning`
                                ] &&
                                  errors[
                                    `exercise${field?.exercise_id}_warning`
                                  ].message}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </form>
        <button
          onClick={() => handleDeleteSlot(slot?.slot_id)}
          className="mt-4 ml-5 inline-flex justify-center rounded-md bg-red-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Borrar Bloque
        </button>
      </>
    ));
  };

  return renderSlots(slots);
}

export default UpdateTemplateSlotForm;
