import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logoSmall from "../../../assets/logo-small.png";
import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository";
import { useAuthRepository } from "../../../repositories/auth/authRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository";

function ListAllTemplates() {
  const { user } = useAuthRepository();
  const { templates, templatesRepository } = useTemplatesRepository();
  const { activities, activitiesRepository } = useActivitiesRepository();
  const [sortedTemplates, setSortedTemplates] = useState([]);

  useEffect(() => {
    if (templates) {
      const sorted = [...templates].sort((a, b) => {
        if (a.activity_id < b.activity_id) return -1;
        if (a.activity_id > b.activity_id) return 1;
        return 0;
      });
      setSortedTemplates(sorted);
    }
  }, [templates]);

  const renderedActivities = new Set();

  return user.role === "admin" ? (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold p-5">Plantillas</h2>
      <ul role="list" className="divide-y divide-gray-100 mb-10">
        {sortedTemplates?.map((template) => {
          const activity = activities.find(
            (activity) => activity.activity_id === template.activity_id
          );
          const renderActivityName = !renderedActivities.has(
            template.activity_id
          );
          if (renderActivityName) {
            renderedActivities.add(template.activity_id);
          }
          return (
            <div key={template?.template_id}>
              {renderActivityName && activity && (
                <h3 className="text-md mt-5 font-semibold leading-7 text-gray-900">
                  {activity.activity_name}
                </h3>
              )}
              <Link
                key={template?.template_id}
                to={`/templates/${template?.template_id}`}
                className="block"
              >
                <li className="flex justify-between gap-x-6 py-5">
                  <div className="flex min-w-0 gap-x-4 flex-1">
                    <img
                      alt=""
                      src={template?.template_logo || logoSmall}
                      className="h-12 w-12 flex-none rounded-full object-cover bg-gray-50"
                    />
                    <div className="flex min-w-0 gap-x-4 flex-1">
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {template?.template_name}
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                          {template?.activity?.activity_name}
                        </p>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm leading-6 text-gray-900">
                        {template?.composition_name}
                      </p>
                      <div className="mt-1 flex items-center gap-x-1.5">
                        <p className="text-xs leading-5 text-gray-500">
                          {template?.composition_name_2}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </Link>
            </div>
          );
        })}
      </ul>
      <Link
        to="/templates/create"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Añadir +
      </Link>
    </div>
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default ListAllTemplates;
