import React, { useState } from "react";

import { useAuthRepository } from "../../repositories/auth/authRepository.js";
import SearchExercises from "../search-exercises/SearchExercises.js";

import CreateSessionForm from "./components/CreateSessionForm.js";

function SessionCreate() {
  const { user } = useAuthRepository();
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle((prevToggle) => !prevToggle);
  };

  return user ? (
    <div className="flex flex-col">
      <button
        onClick={handleToggle}
        className="m-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 self-end sticky top-10 z-20"
      >
        {toggle ? "Mostrar Sesión" : "Mostrar Buscador"}
      </button>
      <div
        style={{
          display: toggle ? "none" : "block",
          height: "70vh",
          overflow: "auto",
          padding: "1rem",
        }}
      >
        <CreateSessionForm />
      </div>
      <div
        style={{
          display: toggle ? "block" : "none",
          height: "70vh",
          overflow: "auto",
          padding: "1rem",
        }}
      >
        <SearchExercises />
      </div>
    </div>
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default SessionCreate;
