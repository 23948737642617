import React, { useEffect, useRef, useState } from "react";

function GenerateUpdateSessionExercises({
  slot,
  register,
  errors,
  handleInputChange,
  defaultExercises,
}) {
  const [dataLoaded, setDataLoaded] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    // Ejecutar handleInputChange con el defaultValue al montarse el componente
    slot.exercises.forEach((exercise, index) => {
      const defaultValue = defaultExercises[index]
        ? Object.values(defaultExercises[index])[0]
        : "";
      handleInputChange({ target: { value: defaultValue?.value } }, exercise);
      setDataLoaded(true);
    });
  }, []);

  // Ordenar los ejercicios por la propiedad order en orden ascendente
  const sortedExercises = [...slot.exercises].sort((a, b) => a.order - b.order);

  return (
    <div key={`${slot.slot_id}`} className="mt-20">
      <h2
        key={`${slot.slot_title}_${slot.slot_id}`}
        className="text-xl font-semibold leading-7 text-gray-900"
      >
        {slot.slot_title}
      </h2>
      <div className="mt-2 flex flex-col sm:grid-cols-6">
        {sortedExercises.map((exercise, index) => {
          const defaultValue = defaultExercises[index]
            ? Object.values(defaultExercises[index])[0]
            : "";
          return (
            <React.Fragment key={`${exercise.exercise_id}_${index}_fragment`}>
              <div
                key={`${exercise.exercise_id}_${index}`}
                className="my-4 sm:col-span-4"
              >
                <label
                  htmlFor={`exercise${exercise.exercise_id}_session`}
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Ejercicio: {index + 1}
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id={`exercise${exercise.exercise_id}_session`}
                      name={`exercise${exercise.exercise_id}_session`}
                      type="number"
                      {...register(`exercise${exercise.exercise_id}_session`, {
                        onChange: (e) => handleInputChange(e, exercise),
                      })}
                      defaultValue={defaultValue?.value}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>
                  <div className="flex gap-x-5 items-center mt-4">
                    {exercise.filter_material && (
                      <p className="px-3 py-2 rounded-sm text-sm text-white bg-red-500">
                        {capitalizeFirstLetter(exercise.filter_material)}
                      </p>
                    )}
                    {exercise.filter_body_part && (
                      <p className="px-3 py-2 rounded-sm text-sm text-white bg-red-500">
                        {capitalizeFirstLetter(exercise.filter_body_part)}
                      </p>
                    )}
                    {exercise.warning && (
                      <p className="px-3 py-2 rounded-sm text-sm text-white bg-yellow-500">
                        {exercise.warning}
                      </p>
                    )}
                    {/* {exercise.repetitions && (
                      <p className="px-3 py-2 rounded-sm text-sm text-white bg-green-500">
                        {exercise.repetitions}
                      </p>
                    )} */}
                  </div>
                </div>
              </div>
              {exercise.repetitions && exercise.repetitions !== "false" && (
                <div key={`${exercise.exercise_id}_repetitions`}>
                  <>
                    <label
                      htmlFor={`exercise${exercise.exercise_id}_session_repetitions`}
                      className="block text-sm font-medium leading-6 text-gray-900 mt-3"
                    >
                      Repeticiones
                    </label>
                    <div className="flex rounded-md gap-x-3 w-32 shadow-sm ring-1 ring-inset mt-2 ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        id={`exercise${exercise.exercise_id}_session_repetitions`}
                        name={`exercise${exercise.exercise_id}_session_repetitions`}
                        type="text"
                        {...register(
                          `exercise${exercise.exercise_id}_session_repetitions`,
                          {
                            required: "Debes ingresar las repeticiones",
                          }
                        )}
                        defaultValue={defaultValue?.repetitions}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                    {errors[
                      `exercise${exercise.exercise_id}_session_repetitions`
                    ] && (
                      <p className="h-4 text-sm text-red-500">
                        {
                          errors[
                            `exercise${exercise.exercise_id}_session_repetitions`
                          ].message
                        }
                      </p>
                    )}
                  </>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default GenerateUpdateSessionExercises;
