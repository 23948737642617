import {
  child,
  ref as databaseRef,
  get,
  remove,
  update,
} from "firebase/database";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

class CompaniesRepositoryFirebase {
  constructor() {
    this.stores = {
      companies: atom(null),
    };

    this.getCompanies();
  }

  async getCompanies() {
    let companies = [];
    try {
      const companiesPath = `/companies/`;
      const companiesSnapshot = await get(
        child(databaseRef(firebase.db), companiesPath)
      );

      if (companiesSnapshot.exists()) {
        companies = Object.values(companiesSnapshot.val());
        this.stores.companies.set(companies);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getCompanyById(id) {
    let company = null;
    try {
      const companyPath = `/companies/${id}`;
      const companySnapshot = await get(
        child(databaseRef(firebase.db), companyPath)
      );

      if (companySnapshot.exists()) {
        company = companySnapshot.val();
      }
    } catch (error) {
      console.error(error);
    }

    return company;
  }

  async updateCompany(id, data) {
    try {
      const companyPath = `/companies/${id}`;
      const companyRef = child(databaseRef(firebase.db), companyPath);

      // Obtener el documento actual
      const snapshot = await get(companyRef);
      if (!snapshot.exists()) {
        throw new Error("Company not found");
      }
      const currentCompany = snapshot.val();

      // Verificar si hay un company_logo anterior y eliminarlo
      if (currentCompany?.company_logo && data?.image[0]?.name) {
        // Obtener la referencia a la carpeta de imágenes de la compañía en Firebase Storage
        const storage = getStorage();
        const folderRef = storageRef(storage, `companies/${id}`);

        // Listar todos los archivos en la carpeta
        const listResult = await listAll(folderRef);

        // Eliminar cada archivo en la carpeta
        const deletePromises = listResult.items.map((fileRef) =>
          deleteObject(fileRef)
        );
        await Promise.all(deletePromises);
      }

      // Verificar si data contiene un archivo en la propiedad company_logo
      if (
        data.image &&
        data.image.length > 0 &&
        data.image[0] instanceof File
      ) {
        const storage = getStorage();
        const fileRef = storageRef(
          storage,
          `companies/${id}/${data.image[0].name}`
        );

        // Subir el archivo a Firebase Storage
        await uploadBytes(fileRef, data.image[0]);

        // Obtener la URL de descarga del archivo
        const fileUrl = await getDownloadURL(fileRef);

        // Actualizar data con la URL del archivo
        data.company_logo = fileUrl;
      } else {
        // Mantener la propiedad company_logo si no se subieron cambios
        data.company_logo = currentCompany.company_logo || "";
      }

      // Actualizar la base de datos con data
      await update(companyRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async createCompany(id, data) {
    try {
      const companyPath = `/companies/${id}`;
      const companyRef = child(databaseRef(firebase.db), companyPath);

      // Verificar si data contiene un archivo en la propiedad company_logo
      if (
        data.image &&
        data.image.length > 0 &&
        data.image[0] instanceof File
      ) {
        const storage = getStorage();
        const fileRef = storageRef(
          storage,
          `companies/${id}/${data.image[0].name}`
        );

        // Subir el archivo a Firebase Storage
        await uploadBytes(fileRef, data.image[0]);

        // Obtener la URL de descarga del archivo
        const fileUrl = await getDownloadURL(fileRef);

        // Actualizar data con la URL del archivo
        data.company_logo = fileUrl;
      }

      // Crear el documento en la base de datos
      await update(companyRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteCompany(id) {
    try {
      const companyPath = `/companies/${id}`;
      const companyRef = child(databaseRef(firebase.db), companyPath);

      // Eliminar el documento de la base de datos
      await remove(companyRef);

      // Obtener la referencia a la carpeta de imágenes de la compañía en Firebase Storage
      const storage = getStorage();
      const folderRef = storageRef(storage, `companies/${id}`);

      // Listar todos los archivos en la carpeta
      const listResult = await listAll(folderRef);

      // Eliminar cada archivo en la carpeta
      const deletePromises = listResult.items.map((fileRef) =>
        deleteObject(fileRef)
      );
      await Promise.all(deletePromises);
    } catch (error) {
      console.error(error);
    }
  }
}

export default CompaniesRepositoryFirebase;
