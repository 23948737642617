import {
  child,
  ref as databaseRef,
  get,
  remove,
  update,
} from "firebase/database";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

const months = {
  ENERO: 0,
  FEBRERO: 1,
  MARZO: 2,
  ABRIL: 3,
  MAYO: 4,
  JUNIO: 5,
  JULIO: 6,
  AGOSTO: 7,
  SEPTIEMBRE: 8,
  OCTUBRE: 9,
  NOVIEMBRE: 10,
  DICIEMBRE: 11,
};

const daysOfWeek = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

// Función para obtener el día de la semana a partir de una cadena de fecha
const getDayOfWeek = (sessionDate) => {
  const [day, month, year] = sessionDate.split(" de ");
  const date = new Date(year, months[month.toUpperCase()], day);
  return daysOfWeek[date.getDay()];
};

class SessionsRepositoryFirebase {
  constructor() {
    this.stores = {
      sessions: atom(null),
    };

    this.getSessions();
  }

  async getSessions() {
    let sessions = [];
    try {
      const sessionsPath = `/sessions/`;
      const sessionsSnapshot = await get(
        child(databaseRef(firebase.db), sessionsPath)
      );

      if (sessionsSnapshot.exists()) {
        sessions = Object.values(sessionsSnapshot.val());

        // Filtrar las sesiones que no tengan el estado "Archivada"
        sessions = sessions.filter((session) => session.status !== "Archivada");

        for (const session of sessions) {
          let template;
          const templatePath = `/templates/${session.template_id}`;
          const templateSnapshot = await get(
            child(databaseRef(firebase.db), templatePath)
          );
          if (templateSnapshot.exists()) {
            template = templateSnapshot.val();
          }
          session.template = template;

          let user;
          const userPath = `/users/${session.user_id}`;
          const userSnapshot = await get(
            child(databaseRef(firebase.db), userPath)
          );
          if (userSnapshot.exists()) {
            user = userSnapshot.val();
          }
          session.user = user;
        }
        this.stores.sessions.set(sessions);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getSessionsArchived() {
    let sessions = [];
    try {
      const sessionsPath = `/sessions/`;
      const sessionsSnapshot = await get(
        child(databaseRef(firebase.db), sessionsPath)
      );

      if (sessionsSnapshot.exists()) {
        sessions = Object.values(sessionsSnapshot.val());

        // Filtrar las sesiones que tengan el estado "Archivada"
        sessions = sessions.filter((session) => session.status === "Archivada");

        for (const session of sessions) {
          let template;
          const templatePath = `/templates/${session.template_id}`;
          const templateSnapshot = await get(
            child(databaseRef(firebase.db), templatePath)
          );
          if (templateSnapshot.exists()) {
            template = templateSnapshot.val();
          }
          session.template = template;

          let user;
          const userPath = `/users/${session.user_id}`;
          const userSnapshot = await get(
            child(databaseRef(firebase.db), userPath)
          );
          if (userSnapshot.exists()) {
            user = userSnapshot.val();
          }
          session.user = user;
        }
        this.stores.sessions.set(sessions);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getSessionById(id) {
    let session = null;
    try {
      const sessionPath = `/sessions/${id}`;
      const sessionSnapshot = await get(
        child(databaseRef(firebase.db), sessionPath)
      );

      if (sessionSnapshot.exists()) {
        session = sessionSnapshot.val();
      }
      let template;
      const templatePath = `/templates/${session.template_id}`;
      const templateSnapshot = await get(
        child(databaseRef(firebase.db), templatePath)
      );
      if (templateSnapshot.exists()) {
        template = templateSnapshot.val();
      }
      session.template = template;

      let user;
      const userPath = `/users/${session.user_id}`;
      const userSnapshot = await get(child(databaseRef(firebase.db), userPath));
      if (userSnapshot.exists()) {
        user = userSnapshot.val();
      }
      session.user = user;
    } catch (error) {
      console.error(error);
    }

    return session;
  }

  async createSession(id, data) {
    try {
      const sessionPath = `/sessions/${id}`;
      const sessionRef = child(databaseRef(firebase.db), sessionPath);

      // Crear el documento en la base de datos
      await update(sessionRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async updateSession(data, id) {
    try {
      const sessionPath = `/sessions/${id}`;
      const sessionRef = child(databaseRef(firebase.db), sessionPath);

      // Actualizar la base de datos con data
      await update(sessionRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async updateSessionStatus(id, status) {
    try {
      const sessionPath = `/sessions/${id}`;
      const sessionRef = child(databaseRef(firebase.db), sessionPath);
      // Asegúrate de que status sea un objeto
      const statusUpdate = { status: status };

      // Actualizar la base de datos con data
      await update(sessionRef, statusUpdate);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteSession(id) {
    try {
      const sessionPath = `/sessions/${id}`;
      const sessionRef = child(databaseRef(firebase.db), sessionPath);

      // Eliminar el documento de la base de datos
      await remove(sessionRef);
    } catch (error) {
      console.error(error);
    }
  }

  async exportSessionToJson(id) {
    try {
      const sessionPath = `/sessions/${id}`;
      const sessionRef = child(databaseRef(firebase.db), sessionPath);

      const sessionSnapshot = await get(sessionRef);
      if (!sessionSnapshot.exists()) {
        throw new Error("La sesión no existe");
      }

      const sessionData = sessionSnapshot.val();

      // Obtener la plantilla para el nombre del json
      const templatePath = `/templates/${sessionData.template_id}`;
      const templateRef = child(databaseRef(firebase.db), templatePath);

      const templateSnapshot = await get(templateRef);
      if (!templateSnapshot.exists()) {
        throw new Error("La plantilla no existe");
      }

      const templateData = templateSnapshot.val();

      // Crear el objeto JSON en el formato especificado
      const formatVideoNumber = (number) => {
        return number.toString().padStart(4, "0");
      };

      const sessionJson = [];

      // Ordenar las claves de sessionData
      const sortedKeys = Object.keys(sessionData)
        .filter((key) => key.startsWith("exercise_session_"))
        .sort((a, b) => {
          const aNumber = parseInt(a.split("_").pop(), 10);
          const bNumber = parseInt(b.split("_").pop(), 10);
          return aNumber - bNumber;
        });

      // Sacamos el día de la semana
      const dayOfWeek = getDayOfWeek(sessionData.sessionDate);

      // Añadir los ejercicios ordenados al JSON
      sortedKeys.forEach((key) => {
        const exerciseNumber = key.split("_").pop();
        const value = sessionData[key].value;
        sessionJson.push({
          compositionName: `${templateData.composition_name} Ejercicio ${exerciseNumber}`,
          videoNumber: `T${formatVideoNumber(value)}`,
        });
      });

      sortedKeys.forEach((key) => {
        const exerciseNumber = key.split("_").pop();
        const value = sessionData[key].value;
        sessionJson.push({
          compositionName: `${templateData.composition_name_2} pies ejercicio ${exerciseNumber}`,
          videoNumber: `P${formatVideoNumber(value)}`,
        });
      });

      const sessionJsonString = JSON.stringify(sessionJson, null, 2);

      const capitalizeWords = (str) => {
        return str
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };

      // Aplicar la función a templateData.template_name
      const formattedTemplateName = capitalizeWords(templateData.template_name);

      const blob = new Blob([sessionJsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${sessionData.day} ${dayOfWeek} - ${formattedTemplateName}.json`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting session to JSON:", error);
    }
  }
}

export default SessionsRepositoryFirebase;
