import React from "react";

import { useSearchExercises } from "../../search-exercises/useSearchExercises.js";

function GenerateSlotExercises({ fields, register, errors }) {
  const { filters } = useSearchExercises();

  const renderFields = () => {
    return fields.map((field, index) => (
      <div key={index} className="mt-12">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12 max-w-4xl mx-auto">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Ejercicio {index + 1}
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Filtro de material
                </label>
                <div className="mt-1">
                  <select
                    id={`exercise${index}_filter_material`}
                    name={`exercise${index}_filter_material`}
                    {...register(`exercise${index}_filter_material`)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value={""}>SIN FILTRO</option>
                    {filters[2]?.options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors[`exercise${index}_filter_material`] &&
                    errors[`exercise${index}_filter_material`].message}
                </p>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Filtro de parte corporal
                </label>
                <div className="mt-1">
                  <select
                    id={`exercise${index}_filter_body_part`}
                    name={`exercise${index}_filter_body_part`}
                    {...register(`exercise${index}_filter_body_part`)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value={""}>SIN FILTRO</option>
                    {filters[3]?.options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors[`exercise${index}_filter_body_part`] &&
                    errors[`exercise${index}_filter_body_part`].message}
                </p>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor={`exercise${index}_repetitions`}
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Repeticiones
                </label>
                {/* <div className="mt-1">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id={`exercise${index}_repetitions`}
                      name={`exercise${index}_repetitions`}
                      type="text"
                      {...register(`exercise${index}_repetitions`)}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="h-4 text-sm text-red-500">
                    {errors[`exercise${index}_repetitions`] &&
                      errors[`exercise${index}_repetitions`].message}
                  </p>
                </div> */}
                <div className="mt-1 flex gap-x-5">
                  <div className="flex items-center gap-x-3">
                    <input
                      defaultChecked
                      id={`exercise${index}_repetitions`}
                      name={`exercise${index}_repetitions`}
                      {...register(`exercise${index}_repetitions`)}
                      type="radio"
                      value={true}
                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                    />
                    <label
                      htmlFor="trueRepetitions"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      Sí
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id={`exercise${index}_repetitions`}
                      name={`exercise${index}_repetitions`}
                      {...register(`exercise${index}_repetitions`)}
                      type="radio"
                      value={false}
                      defaultChecked
                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                    />
                    <label
                      htmlFor="falseRepetitions"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor={`exercise${index}_warning`}
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Advertencia
                </label>
                <div className="mt-1">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id={`exercise${index}_warning`}
                      name={`exercise${index}_warning`}
                      type="text"
                      {...register(`exercise${index}_warning`)}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="h-4 text-sm text-red-500">
                    {errors[`exercise${index}_warning`] &&
                      errors[`exercise${index}_warning`].message}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return <>{renderFields()}</>;
}

export default GenerateSlotExercises;
